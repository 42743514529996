











































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";

@Observer
@Component({})
export default class MonsterDialog extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;
}
